import './_settingsPage.scss';
import React from 'react';
import {Settings, SettingsKey} from '@mp/common/types';
import {TileItemsSettings} from './ui/TileItemsSettings';
import {updateSettingsService} from './services';
import {CustomInput} from './ui/CustomInput';
import {getAppSettings} from '../../global';

interface SettingPageState {
    todo?: boolean;
}

export class SettingsPage extends React.Component<{}, SettingPageState> {
    private appSettings: Settings;

    constructor(props: {}) {
        super(props);

        this.appSettings = getAppSettings();
    }

    public render() {
        return (
            <div className="mp-settings-page">
                <SettingsSection title="Pasek nawigacji">
                    <TileItemsSettings
                        tileItemIds={this.appSettings.headerItems}
                        onSaveClick={(json: string) => this.updateAndReload(SettingsKey.headerItems, json)}
                    />
                </SettingsSection>
                <SettingsSection title="Strona główna">
                    <TileItemsSettings
                        isHome={true}
                        tileItemIds={this.appSettings.homeItems}
                        onSaveClick={(json: string) => this.updateAndReload(SettingsKey.homeItems, json)}
                    />
                </SettingsSection>
                <SettingsSection title="Bucket lista">
                    <CustomInput
                        type="checkbox"
                        title="domyślnie widoczne szczegóły"
                        defaultValue={this.appSettings.bucketListPageVisibleDetails}
                        onSave={(value) => this.updateAndReload(SettingsKey.bucketListPageVisibleDetails, value)}
                    />
                </SettingsSection>
                <SettingsSection title="Lista zakupów">
                    <CustomInput
                        type="text"
                        title="liczba wyników wyszukiwarki"
                        defaultValue={this.appSettings.shoppingListPageWordWheelCount}
                        onSave={(value) => this.updateAndReload(SettingsKey.shoppingListPageWordWheelCount, value)}
                    />
                </SettingsSection>
                <SettingsSection title="Wyszukiwarka filmwów">
                    <>
                        <CustomInput
                            type="checkbox"
                            title="pokazuj id w wynikach wyszukiwania"
                            defaultValue={this.appSettings.moviesPageSearchResultShowId}
                            onSave={(value) => this.updateAndReload(SettingsKey.moviesPageSearchResultShowId, value)}
                        />
                        <CustomInput
                            type="checkbox"
                            title="pobieraj ocenę krytyków"
                            defaultValue={this.appSettings.moviesPageSearchIncludeCriticsRating}
                            onSave={(value) => this.updateAndReload(SettingsKey.moviesPageSearchIncludeCriticsRating, value)}
                        />
                    </>
                </SettingsSection>
            </div>
        );
    }

    private updateAndReload(key: SettingsKey, value: string): void {
        updateSettingsService(key, value).then(({success}) => success && window.location.reload());
    }
}

function SettingsSection(params: {title: string; children: JSX.Element}): JSX.Element {
    return (
        <section>
            <div className="settings-title">
                <span>{params.title}</span>
            </div>
            <div className="settings-content">{params.children}</div>
        </section>
    );
}
