import './_episodesPage.scss';
import {useParams} from 'react-router-dom';
import React from 'react';
import {AirDatesData, loadFilmEpisodesAirDatesService} from '@mp/filmweb/services/loadFilmEpisodesAirDates.service';
import {DateInput} from '@mp/common/components/date-input/ui/DateInput';
import {Button} from '@mp/common/components/button/ui/Button';
import {getCurrentDateString} from '@mp/common/utils/date';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {EpisodesTab, Router} from '@mp/route';
import {Modal} from '@mp/common/modals/Modal';
import {ViewMode} from '@mp/common/consts';
import {
    insertSeriesService,
    updateEpisodeService,
    updateSeriesService,
    updateEpisodeNextAirDateService,
    loadSeriesService
} from './services';
import {TabItem, TabsNavigation} from '../../components/tabs-navigation/ui/TabsNavigation';
import {createEpisodeIdWithDate} from './utils/episodeHelper';
import {getModalInputsData} from './utils/getModalInputData';
import {CheckNewEpisodeState, Series, SeriesDTO} from './types';
import {Loading} from '../../components/loading';
import {SeriesItem} from './ui/SeriesItem';

const MP_EPISODES_PAGE: 'mp-series-page' = 'mp-series-page';

interface EpisodesPageState {
    viewMode: ViewMode;
    isLoaded: boolean;
    visibleSeries: Array<Series>;
    invisibleSeries: Array<Series>;
    updateAirDate: Series;
}

interface EpisodesPageProps {
    selectedTab: EpisodesTab;
}

export function EpisodesPage(): JSX.Element {
    const {tab} = useParams<{tab}>();

    return <EpisodesPageCC selectedTab={tab as EpisodesTab} />;
}

let _allSeries: Array<Series>;

class EpisodesPageCC extends React.Component<EpisodesPageProps, EpisodesPageState> {
    private selectedSeries: Partial<Series> = {};

    constructor(props: EpisodesPageProps) {
        super(props);

        this.incrementEpisode = this.incrementEpisode.bind(this);
        this.updateSeries = this.updateSeries.bind(this);
        this.addSeries = this.addSeries.bind(this);

        this.state = {
            viewMode: ViewMode.Normal,
            isLoaded: false,
            updateAirDate: null,
            visibleSeries: null,
            invisibleSeries: null
        };
    }

    public componentDidMount() {
        this.loadData(false);
    }

    public render() {
        const {isLoaded, viewMode} = this.state;

        if (!isLoaded) {
            return <Loading />;
        }
        if (viewMode === ViewMode.Normal) {
            return this.renderNormalView();
        }
        if (viewMode === ViewMode.Add) {
            return (
                <FormsModal
                    title="Dodaj nowy serial"
                    mode="add"
                    inputs={getModalInputsData(this.selectedSeries)}
                    handleClose={() => this.setNormalView()}
                    onSendForm={(data) => this.addSeries(data)}
                />
            );
        }
        if (viewMode === ViewMode.Edit) {
            return (
                <FormsModal<Series>
                    title="Edytuj serial"
                    mode="update"
                    inputs={getModalInputsData(this.selectedSeries)}
                    handleClose={() => this.setNormalView()}
                    onSendForm={(data) => this.updateSeries(data)}
                />
            );
        }
        return null;
    }

    private loadData(forceServiceCall: boolean = true) {
        if (_allSeries == null || forceServiceCall) {
            loadSeriesService().then((response: Array<Series>) => {
                _allSeries = response;
                const {visibleSeries, invisibleSeries} = splitSeries();
                this.setState({invisibleSeries, visibleSeries, isLoaded: true}, () => this.checkEpisodes());
            });
        } else {
            const {visibleSeries, invisibleSeries} = splitSeries();
            this.setState({invisibleSeries, visibleSeries, isLoaded: true});
        }

        function splitSeries(): {visibleSeries: Array<Series>; invisibleSeries: Array<Series>} {
            const visibleSeries: Array<Series> = _allSeries.filter((f: Series) => f.visible);
            const invisibleSeries: Array<Series> = _allSeries.filter((f: Series) => !f.visible);
            return {invisibleSeries, visibleSeries};
        }
    }

    private incrementEpisode(id: number, episode: number) {
        updateEpisodeService({id, episode: ++episode}).then(() => this.loadData());
    }

    private setEditView(series: Series) {
        this.selectedSeries = series;
        this.setState(() => {
            return {viewMode: ViewMode.Edit};
        });
    }

    private setNormalView() {
        this.selectedSeries = {};
        this.setState(() => {
            return {viewMode: ViewMode.Normal};
        });
    }

    private updateSeries(data: SeriesDTO) {
        return updateSeriesService(data)
            .then(() => this.loadData())
            .then(() => this.setNormalView());
    }

    private addSeries(data: SeriesDTO) {
        return insertSeriesService(data)
            .then(() => this.loadData())
            .then(() => this.setNormalView());
    }

    private renderNormalView(): JSX.Element {
        const {updateAirDate} = this.state;
        const items: Array<Series> =
            this.props.selectedTab === 'active'
                ? this.state.visibleSeries
                : [].concat(this.state.visibleSeries, this.state.invisibleSeries);
        return (
            <>
                <TabsNavigation tabs={this.getTabs()} />
                <div className={MP_EPISODES_PAGE}>
                    {updateAirDate && this.renderUpdateAirDateModal()}
                    <div className={`${MP_EPISODES_PAGE}-items`}>{items.map((series: Series) => this.renderSeriesItem(series))}</div>
                </div>
            </>
        );
    }

    private renderUpdateAirDateModal(): JSX.Element {
        const {id, name} = this.state.updateAirDate;
        let dateInput: HTMLInputElement = null;

        return (
            <Modal title={name} size="small" handleClose={() => handleModalClose(this)} footer={<ModalFooter context={this} />}>
                <div className="air-date-modal-content">
                    <DateInput defaultValue={getCurrentDateString()} reference={(el) => (dateInput = el)} />
                </div>
            </Modal>
        );

        function handleModalClose(context: EpisodesPageCC): void {
            context.setState({updateAirDate: null});
        }

        function ModalFooter({context}: {context: EpisodesPageCC}): JSX.Element {
            return <Button title="Aktualizuj datę emisji" onClick={() => onModalConfirm()} />;

            function onModalConfirm(): void {
                const date: Date = dateInput.value ? new Date(dateInput.value) : null;
                if (date) {
                    date.setHours(0, 0, 0, 0);
                }
                updateEpisodeNextAirDateService(id, createEpisodeIdWithDate(context.state.updateAirDate, date)).then(
                    ({success}) => success && window.location.reload()
                );
            }
        }
    }

    private getTabs(): Array<TabItem> {
        const {selectedTab} = this.props;
        return [
            {name: 'Aktywne', url: Router.getUrlToEpisodesPage('active'), selected: selectedTab === 'active'},
            {name: 'Wszystkie', url: Router.getUrlToEpisodesPage('all'), selected: selectedTab === 'all'},
            {
                name: 'Dodaj serial',
                url: Router.getUrlToEpisodesPage('add'),
                selected: selectedTab === 'add',
                onClick: () => this.setState({viewMode: ViewMode.Add})
            }
        ];
    }

    private renderSeriesItem(series: Series): JSX.Element {
        return (
            <SeriesItem
                key={series.id}
                series={series}
                onIncrementEpisode={(_id, _episode) => this.incrementEpisode(_id, _episode)}
                onSetEditView={(_series) => this.setEditView(_series)}
                onUpdateEmissionDate={(_series) => this.setState({updateAirDate: _series})}
            />
        );
    }

    private checkEpisodes(): void {
        const filmwebSeries: Array<Series> = this.state.visibleSeries.filter((f) => f.fid && f.checkNewEpisodeState === null);
        const temp: Array<Series> = this.state.visibleSeries;

        filmwebSeries.forEach((e: Series) => {
            const item: Series = temp.find((f) => f.id === e.id);
            item.checkNewEpisodeState = CheckNewEpisodeState.LOADING;
        });
        this.setState({visibleSeries: temp});

        filmwebSeries.forEach((series) => {
            loadFilmEpisodesAirDatesService(series.fid, series.season, series.hasSeasons)
                .then((airDatesData: Array<AirDatesData>) => {
                    const nextEpisode: number = series.episode + 1;

                    let filteredAirDatesData: AirDatesData = airDatesData?.find(
                        ({seasonNumber, episodeNumber}) => seasonNumber === series.season && episodeNumber === nextEpisode
                    );

                    if (!filteredAirDatesData) {
                        // when episodes are not divided by season
                        filteredAirDatesData = airDatesData?.find(({episodeNumber}) => episodeNumber === nextEpisode);
                    }

                    const foundAirDate: number =
                        filteredAirDatesData?.airDates.find((ad) => ad.country === 'PL')?.airDateInt ??
                        filteredAirDatesData?.airDates[0]?.airDateInt;

                    let date: Date = null;
                    if (foundAirDate) {
                        const dateString: string = String(foundAirDate);
                        date = new Date(Number(dateString.slice(0, 4)), Number(dateString.slice(4, 6)) - 1, Number(dateString.slice(6, 8)));
                    }
                    this.updateVisibleSeriesState(date, series);
                })
                .catch((rejected) => console.error(rejected));
        });
    }

    private updateVisibleSeriesState(date: Date, series: Series): void {
        const tempVisibleSeriesState: Array<Series> = this.state.visibleSeries;
        const foundSeries: Series = tempVisibleSeriesState.find((s) => s.id === series.id);

        if (date && foundSeries) {
            updateEpisodeNextAirDateService(foundSeries.id, createEpisodeIdWithDate(foundSeries, date)).then();
        }

        foundSeries.checkNewEpisodeState = date ? date : CheckNewEpisodeState.NO_DATE;
        this.setState({visibleSeries: tempVisibleSeriesState});
    }
}
