import './_cinemaProgramPage.scss';
import {Navigate, useParams} from 'react-router-dom';
import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {useQueryParams} from '@mp/common/hooks/useQueryParams';
import {isEmpty, isNotEmpty} from '@mp/common/utils/array';
import {Blink} from '@mp/common/components/blink/ui/Blink';
import {toDateString} from '@mp/common/utils/converters';
import {DATE_PARAM, Router} from '@mp/route';
import {TabsNavigation} from '../../../components/tabs-navigation/ui/TabsNavigation';
import {MovieInfoSingleSchedule, ScheduleModal} from './ScheduleModal';
import {getNavigationTabs} from '../utils/getNavigationTabs';
import {MovieInfoBoxSkeleton} from './MovieInfoBoxSkeleton';
import {resolveMovieId} from '../utils/resolveMovieTimeId';
import {loadCinemaProgramService} from '../services';
import {MovieInfoBox} from './MovieInfoBox';
import {MovieInfo} from '../types';

interface CinemaProgramProps {
    date: Date;
    id: string;
}

export function CinemaProgramPage(): JSX.Element {
    const propsDate: string = useQueryParams(DATE_PARAM);
    const {movieId} = useParams<{movieId}>();

    if (!propsDate) {
        return <Navigate to={Router.getUrlToCinemaProgramPage({date: new Date()})} />;
    }
    return <CinemaProgramPageFC date={new Date(propsDate)} id={movieId} />;
}

function CinemaProgramPageFC({date, id}: CinemaProgramProps): JSX.Element {
    const dateToMovieInfosMap: MutableRefObject<Map<string, Array<MovieInfo>>> = useRef(new Map<string, Array<MovieInfo>>());
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [moviesInfo, setMoviesInfo] = useState<Array<MovieInfo>>([]);
    const [selectedMovieInfo, setSelectedMovieInfo] = useState<MovieInfoSingleSchedule>(null);

    useEffect(() => {
        setIsLoaded(false);
        const dateString: string = toDateString(date, 'yyyy-mm-dd');
        if (dateToMovieInfosMap.current.get(dateString)) {
            setMoviesInfo(dateToMovieInfosMap.current.get(dateString));
            setIsLoaded(true);
            return;
        }

        loadCinemaProgramService(date).then((movieInfos) => {
            if (isNotEmpty(movieInfos)) {
                dateToMovieInfosMap.current.set(dateString, movieInfos);
            }
            setMoviesInfo(movieInfos);
            setIsLoaded(true);
        });
    }, [date]);

    return (
        <div className="mp-cinema-program">
            <TabsNavigation tabs={getNavigationTabs(date)} />
            {renderProgram()}
        </div>
    );

    function selectMovieBasedOnId(): void {
        if (id) {
            requestAnimationFrame(() => {
                const color: string = '#373737';
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.style.backgroundColor = color;
                    element.scrollIntoView();
                } else {
                    const timeScheduleElement: HTMLElement = document.querySelector(`[custom-stime="${id}"]`);
                    if (timeScheduleElement) {
                        timeScheduleElement.setAttribute('selected', String(true));
                        const movieEl: HTMLElement = document.getElementById(resolveMovieId(id));
                        if (movieEl) {
                            movieEl.scrollIntoView();
                            movieEl.style.backgroundColor = color;
                        }
                    }
                }
            });
        }
    }

    function renderProgram(): JSX.Element {
        if (!isLoaded) {
            return (
                <>
                    {[{}, {}, {}].map((value, index) => (
                        <MovieInfoBoxSkeleton key={index} />
                    ))}
                </>
            );
        }

        if (moviesInfo == null) {
            return <div className="empty-program">Nie udało się pobrać repertuaru</div>;
        }

        if (isEmpty(moviesInfo)) {
            return <div className="empty-program">Brak seansów na wybrany dzień</div>;
        }

        selectMovieBasedOnId();

        return (
            <>
                {moviesInfo.map((movieInfo) => (
                    <MovieInfoBox
                        key={movieInfo.title}
                        movieInfo={movieInfo}
                        date={date}
                        onScheduleClick={(schedule) => setSelectedMovieInfo({movieInfo, schedule})}
                    />
                ))}
                <div className="mp-cinema-program-footer">
                    <Blink text="Wziąć słomki" />
                </div>
                {selectedMovieInfo && (
                    <ScheduleModal date={date} selectedMovieInfo={selectedMovieInfo} handleClose={() => setSelectedMovieInfo(null)} />
                )}
            </>
        );
    }
}
