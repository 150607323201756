import {InputData} from '@mp/common/components/form-input/types';
import {Series} from '../types';

export function getModalInputsData(selectedSeries: Partial<Series>): Array<InputData<Series>> {
    const formInputs: Array<InputData<Series>> = [
        {displayName: 'Dzień', id: 'day', type: 'text-number', defaultValue: selectedSeries.day ?? 1},
        {displayName: 'Filmweb id', id: 'fid', type: 'text-number', defaultValue: selectedSeries.fid},
        {displayName: 'Filmweb (podział na sezony)', id: 'hasSeasons', type: 'checkbox', defaultValue: selectedSeries.hasSeasons},
        {displayName: 'Nazwa', id: 'name', type: 'text', defaultValue: selectedSeries.name || '', validation: {minLength: 3}},
        {displayName: 'Sezon', id: 'season', type: 'text-number', defaultValue: selectedSeries.season ?? 0},
        {displayName: 'Odcinek', id: 'episode', type: 'text-number', defaultValue: selectedSeries.episode ?? 0},
        {displayName: 'Link do oglądania', id: 'link', type: 'text', defaultValue: selectedSeries.link || ''},
        {displayName: 'Koniec', id: 'end', type: 'checkbox', defaultValue: selectedSeries.end ?? false},
        {displayName: 'Widoczny', id: 'visible', type: 'checkbox', defaultValue: selectedSeries.visible ?? true}
    ];

    if (selectedSeries) {
        formInputs.push({displayName: null, id: 'id', type: 'hidden', defaultValue: selectedSeries.id});
    }

    return formInputs;
}
